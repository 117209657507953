import { type ReactNode } from "react"
import { Navigate, useLocation } from "react-router-dom"
import { useAppSelector } from "../app/typedRedux"
import { loginStateSelector } from "../redux/authSlice"

/**
 * Navigates to the login screen if the user is unauthenticated.
 */
export default function RequireAuth({children} : {children?: ReactNode}) {
  const loginState = useAppSelector(loginStateSelector)
  const location = useLocation()

  return loginState === "loggedOut"
    ? <Navigate to="/login" replace={true} state={{next: location.pathname}}/>
    : <>{children}</>
}
