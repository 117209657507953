import { useDispatch, useSelector, useStore } from "react-redux";
import type Store from "../redux/Store";
import type { AppDispatch, RootState } from "../redux/Store";
import { addListener } from "@reduxjs/toolkit";

export const addAppListener = addListener.withTypes<RootState>()

export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()
export const useAppStore = useStore.withTypes<typeof Store>()
