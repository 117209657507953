import type {
  ReactElement,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  useId
} from "react"
import useTranslateError from "../hooks/useTranslateError"

/**
 * Displays a message when an HTML element has had an error.
 */
export default function ErrorMessage(
  {
    className,
    id,
    message,
  }: {
    /**
     * `class` of `<p>` element.
     * 
     * Setting this will remove default styling.
     */
    className?: string
    /**
     * `id` of the error message element.
     * 
     * This is necessary for usage with `aria-errormessage`. An id can easily be
     * created using {@link useId}.
     * 
     * If there really shouldn't be an `id`, use `undefined`.
     */
    id: string | undefined
    /**
     * Error message to display. If no error has occured, should be an empty
     * string, `undefined`, or `null`.
     * 
     * If this is a {@link ReactElement}, it must only contain
     * [phrasing content](https://developer.mozilla.org/en-US/docs/Web/HTML/Content_categories#phrasing_content).
     */
    message: ReactElement | string | undefined | null
  }
) {
  const translateError = useTranslateError()

  // Always keep the <p> present since it uses aria-live:
  return <p
    className={className}
    style={{
      ...(className ? null : {"color": "#7f1d1d"})
    }}
    id={id}
    role="alert"
    aria-atomic="true"
  >
    {
      typeof message === "string" && message
        ? translateError(message)
        : message
    }
  </p>

}
