import { createAsyncThunk, createSlice, miniSerializeError, type SerializedError } from "@reduxjs/toolkit";
import axios, { isAxiosError, type AxiosError } from "axios";
import backend from "../axios/backend";
import { backendUrl } from "../config";
import type { AppDispatch, RootState } from "./Store";

type AuthState = {
  authStatus: "loggedIn"
  id: string
  username: string
  email: string
  firstName: string
  lastName: string
} | {
  authStatus: "unknown" | "pending" | "loggedOut"
}

const initialState: AuthState = {
  authStatus: "unknown",
}

export type SerializedAxiosError = SerializedError & {
  response?: {
    data: any
    status: number
    statusText: string
    headers: any
  }
}

/**
 * Creates a {@link SerializedError} based on a given error while properly
 * converting {@link AxiosError}s.
 */
function serializeAxiosError(error: unknown) {
  const newError = miniSerializeError(error)

  if (isAxiosError(error) && error.response !== undefined) {
    (newError as SerializedAxiosError).response = {
      data: error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
      headers: JSON.parse(JSON.stringify(error.response.headers)),
    }
  }

  return newError
}

/**
 * Creates a thunk action which logs the user **in**.
 */
export const logIn = createAsyncThunk(
  "auth/logIn",
  async ({identity, password}: {identity: string, password: string}) => {
    // Can't use the "backend" axios instance since that awaits any pending
    // authentication, which would cause a permanent await:
    const res = await axios.post(
      "api/users/login",
      { identity, password },
      {
        baseURL: backendUrl,
        withCredentials: true,
      }
    )

    return res.data.user
  },
  {
    condition(_, {getState}) {
      // Trying to log in while already logged in will cause problems:
      return loginStateSelector(getState() as RootState) !== "loggedIn"
    },
    serializeError: serializeAxiosError,
  }
)

/**
 * Creates a thunk action which logs the user **out**.
 */
export const logOut = createAsyncThunk(
  "auth/logOut",
  async () => {
    // Can't use the "backend" axios instance since that awaits any pending
    // authentication, which would cause a permanent await:
    await axios.post(
      "api/users/logout",
      undefined,
      {
        baseURL: backendUrl,
        withCredentials: true,
      }
    )
  },
  {
    serializeError: serializeAxiosError,
  }
)

/**
 * Creates a thunk which updates the state based on any possible ongoing
 * authenticated session.
 */
export const checkAuth = createAsyncThunk(
  "auth/check",
  async () => {
    // GETs the current session info:
    const res = await backend.get("api/users/login")
    return res.data.user
  },
  {
    condition(_, {getState}) {
      return loginStateSelector(getState() as RootState) !== "pending"
    },
    serializeError: serializeAxiosError,
  }
)

function logInReducer(state: AuthState, user: any) {
  const s = state as Partial<AuthState & {authStatus: "loggedIn"}>
  s.authStatus = "loggedIn"
  s.id = user.id
  s.username = user.username
  s.email = user.email
  s.firstName = user.first_name
  s.lastName = user.last_name
}

function logOutReducer(state: AuthState) {
  state.authStatus = "loggedOut"
  const s = state as Partial<AuthState & {authStatus: "loggedIn"}>
  delete s.id
  delete s.username
  delete s.email
  delete s.firstName
  delete s.lastName
}

const authSlice = createSlice(
  {
    name: "auth",
    initialState: initialState as AuthState,
    reducers: {
      logOut(state) {
        state.authStatus = "loggedOut"
      },
    },
    extraReducers(builder) {
      builder
        // Logging in
        .addCase(logIn.fulfilled, (state, {payload: user}) => {
          logInReducer(state, user)
        })
        .addCase(logIn.rejected, state => {
          if (state.authStatus === "loggedIn") return
          logOutReducer(state)
        })
        .addCase(logIn.pending, state => {
          if (state.authStatus === "loggedIn") return
          state.authStatus = "pending"
        })
        // Logging out
        .addCase(logOut.fulfilled, state => {
          logOutReducer(state)
        })
        .addCase(logOut.rejected, state => {
          // Likely caused by being offline

          if (state.authStatus === "loggedOut") return

          if (Object.hasOwn(state, "id")) {
            // We had user data, so we were logged in
            state.authStatus = "loggedIn"
          }

          // HACK There could be another pending operation, in which case we
          //      shouldn't be setting this to unknown, but better to set to
          //      unknown than risk pending forever if there isn't one. This
          //      edge case should never happen anyway:
          state.authStatus = "unknown"
        })
        .addCase(logOut.pending, state => {
          if (state.authStatus === "loggedOut") return
          state.authStatus = "pending"
        })
        // Resuming
        .addCase(checkAuth.fulfilled, (state, {payload: user}) => {
          logInReducer(state, user)
        })
        .addCase(checkAuth.rejected, (state, {error}) => {
          // 401 means we weren't authenticated, other codes are genuine errors:
          if ((error as SerializedAxiosError).response?.status !== 401) return

          logOutReducer(state)
        })
    },
  }
)

/**
 * Selects the status of the ongoing login.
 */
export const loginStateSelector = (state: RootState) => state.auth.authStatus

/** Selects auth info for the current session. */
export const selectAuthInfo = (state: RootState) => state.auth

/**
 * Attempts to resume authentication if the session was previously authenticated
 * but the app was unloaded (such as when refreshing).
 * 
 * @param dispatch The dispatch method of the store to use.
 */
export function tryResumeAuth(dispatch: AppDispatch) {
  dispatch(checkAuth())
}

export default authSlice.reducer
