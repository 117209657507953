import { configureStore, createListenerMiddleware } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import gamesReducer from "./gamesSlice";
import collegesReducer from "./collegesSlice";
import i18nReducer from "./i18nSlice";

const listenerMiddleware = createListenerMiddleware()

const store = configureStore({
  reducer: {
    auth: authReducer,
    games: gamesReducer,
    colleges: collegesReducer,
    i18n: i18nReducer,
    // Add other reducers if needed
  },

  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware()
      .prepend(listenerMiddleware.middleware)
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
