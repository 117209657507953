import { Link } from 'react-router-dom';


const gamesList = [
  { name: 'Biobot', image: '/images/biobot.png', route: '/biobot' },
  { name: 'Arcana Apotheca', image: '/images/arcana.png', route: '/arcana_apotheca' },
  { name: 'Vector Shock', image: '/images/vectorshock.png', route: '/vector_shock' },
];

const Games = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 p-8">
      {gamesList.map((game, index) => (
        <div key={index}>
          <Link to={game.route}>
            <img src={game.image} alt={game.name} className="w-full h-auto" />
            <h2 className="text-center mt-4 text-lg font-bold">{game.name}</h2>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default Games;