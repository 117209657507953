import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "./Store";
import backend from "../axios/backend";
interface College {
  _id: { $oid: string };
  name: String;
}

interface CollegesState {
  data: College[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: CollegesState = {
  data: [],
  status: "idle",
  error: null,
};

export const fetchColleges = createAsyncThunk(
  "colleges/fetchColleges",
  async () => {
    const response = await backend.get("api/colleges");
    return response.data.result;
  }
);

const collegesSlice = createSlice({
  name: "colleges",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchColleges.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchColleges.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchColleges.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ?? "Failed to fetch games";
      });
  },
});

export const selectAllColleges = (state: RootState) => state.colleges.data;
export const selectCollegesStatus = (state: RootState) => state.colleges.status;

export default collegesSlice.reducer;
