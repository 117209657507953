import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "./Store";
import backend from "../axios/backend";
interface Game {
  _id: { $oid: string };
  description: string;
  subject: string;
  title: string;
}

interface GamesState {
  data: Game[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: GamesState = {
  data: [],
  status: "idle",
  error: null,
};

export const fetchGames = createAsyncThunk("games/fetchGames", async () => {
  const response = await backend.get("api/games");
  return response.data.result;
});

const gamesSlice = createSlice({
  name: "games",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGames.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchGames.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchGames.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ?? "Failed to fetch games";
      });
  },
});

export const selectAllGames = (state: RootState) => state.games.data;
export const selectGamesStatus = (state: RootState) => state.games.status;

export default gamesSlice.reducer;
