import { useAppSelector } from "../app/typedRedux";
import { selectTranslations } from "../redux/i18nSlice";
import "./Home.css";

function Home() {
  const t = useAppSelector(selectTranslations);
  return (
    <section className="relative min-h-screen w-full overflow-hidden bg-accent-primary pt-32 flex justify-center items-center">
      <div className="content-container flex justify-center items-center w-full">
        <div className="text-container">
          <h1 className="title">{t.eduteq_games}</h1>
          <p className="subtitle uppercase">{t.embark_on_a_learning_journey}</p>
        </div>
        <div className="wrap-welcome relative">
          <div className="circle large-circle">
            <img
              src="/images/mascot.svg"
              className="mascot"
              alt="Eduteq Mascot"
            />
          </div>
          <div className="circle small-circle circle1"></div>
          <div className="circle small-circle circle2"></div>
          <div className="circle small-circle circle3"></div>
        </div>
      </div>
      <div
        className="red-gear"
        style={{
          background: "url('/images/red-gear.png') no-repeat center center",
          backgroundSize: "contain",
          animation: "rotateGear 10s linear infinite",
        }}
      ></div>
    </section>
  );
}

export default Home;
