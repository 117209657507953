import { useLayoutEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../app/typedRedux";
import type RequireAuth from "../components/RequireAuth"; // eslint-disable-line @typescript-eslint/no-unused-vars
import { selectAuthInfo } from "../redux/authSlice";

/**
 * Navigates to the home screen if the user is ***authenticated***.
 * 
 * ***Do not confuse this with {@link RequireAuth}.***
 * 
 * This is only checked when the component is first mounted to prevent problems
 * with other components waiting for authentication to finish.
 */
export default function useRequireUnauth() {
  const navigate = useNavigate();
  const authState = useAppSelector(selectAuthInfo);
  const stopChecking = useRef(false);

  useLayoutEffect(() => {
    if (stopChecking.current) return;
    if (authState.authStatus === "loggedIn") navigate("/", {replace: true});
    else if (authState.authStatus === "loggedOut") stopChecking.current = true;
  }, [authState, navigate]);
}
