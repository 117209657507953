import { forwardRef, type ForwardedRef, type ReactNode } from "react"
import { useAppSelector } from "../app/typedRedux"
import { selectTranslations } from "../redux/i18nSlice"

/**
 * A `<button>` styled for saving something.
 */
const SaveButton = forwardRef((
  props: Omit<JSX.IntrinsicElements["button"], "children"> & {
    /**
     * Name presented to users through aria for the thing being saved.
     */
    ariaName: string
    children?: ReactNode
  },
  /** Ref to the `<button>` element. */
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const t = useAppSelector(selectTranslations)

  const buttonProps: JSX.IntrinsicElements["button"] & Partial<typeof props> = {
    ...props
  }
  delete buttonProps.ariaName

  return <button
    children={t.save}
    aria-label={t.save_x.replace("{}", props.ariaName)}
    {...buttonProps}
    ref={ref}
  />
})

export default SaveButton
