import React, { useEffect, useId, useState, type FormEvent } from "react";
import Customizer, { createDefaultAvatar } from "../components/Customizer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/typedRedux";
import backend from "../axios/backend";
import { selectTranslations } from "../redux/i18nSlice";
import usePromise from "../hooks/usePromise";
import { checkAuth } from "../redux/authSlice";
import {
  fetchColleges,
  selectAllColleges,
  selectCollegesStatus,
} from "../redux/collegesSlice";
import type { UserInfoPatch } from "../types/UserInfo";
import rethrowFormErrorContents, { type FieldErrors, type RethrownFormErrorContents } from "../util/rethrowFormErrorContents";
import ErrorMessage from "./ErrorMessage";
import { useInitColleges } from "../hooks/useEnsureCollegesAreFetched";

const RegisterForm: React.FC = () => {
  const t = useAppSelector(selectTranslations);

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [avatar, setAvatar] = useState(createDefaultAvatar());
  const [collegeId, setCollegeId] = useState("");

  const dispatch = useAppDispatch();
  const colleges = useAppSelector(selectAllColleges);
  const collegesStatus = useAppSelector(selectCollegesStatus);

  type RegisterFieldErrors = FieldErrors<UserInfoPatch>;
  type SubmissionError = RethrownFormErrorContents<UserInfoPatch>;
  const {
    error: submissionError,
    isPending: isSubmitting,
    observePromise: observeSubmissionPromise,
  } = usePromise<void, SubmissionError>();

  const {
    email: emailError,
    username: usernameError,
    password: passwordError,
    first_name: firstNameError,
    last_name: lastNameError,
  } = typeof submissionError === "object"
    ? submissionError
    // No field errors; empty object:
    : {} satisfies RegisterFieldErrors;

  const emailErrorMessageId = useId();
  const usernameErrorMessageId = useId();
  const passwordErrorMessageId = useId();
  const firstNameErrorMessageId = useId();
  const lastNameErrorMessageId = useId();

  const navigate = useNavigate();
  const state = useLocation().state;

  useInitColleges();

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    observeSubmissionPromise((async () => {
      try {
        // Create the account
        await backend.post("api/users/signup", {
          username,
          password,
          email,
          first_name: firstName,
          last_name: lastName,
          avatar: avatar,
          college: collegeId,
        } satisfies Required<UserInfoPatch>);

        // TODO We're now logged in, but in the future email verification will
        //      be involved.
        await dispatch(checkAuth()).unwrap();
        navigate(state?.next ?? "/");
      } catch (er) {
        rethrowFormErrorContents(er);
      };
    })());
  }

  /**
   * `true` if there error is a specific error that should be shown by the
   * submit button, `false` if a generic error message should be shown instead.
   */
  const hasSubmitButtonError = typeof submissionError === "string";

  return (
    /*<div className="max-w-3xl m-auto px-5 py-24">
      <div className="text-accent-white">
        <img
          className="mx-auto h-10 w-auto"
          src="/images/character-white.png"
        />
        <h2 className="mt-5 text-center text-7xl">Register</h2>
        <p className="mt-5">We're happy you're taking the initiative!</p>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form
          className="space-y-6"
          onSubmit={onSubmit}
          action="#"
          method="POST"
        >
          <div>
            <div className="mt-2">
              <input
                id="username"
                name="username"
                required
                placeholder="Username"
                className="block w-full rounded-xl py-3 px-4"
                value={username}
                onInput={(e) => setUsername(e.currentTarget.value)}
              />
            </div>
          </div>

          <div>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                required
                placeholder="Email Address"
                className="block w-full rounded-xl py-3 px-4"
                value={email}
                onInput={(e) => setEmail(e.currentTarget.value)}
              />
            </div>
          </div>

          <div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                required
                placeholder="Password"
                className="block w-full rounded-xl py-3 px-4"
                value={password}
                onInput={(e) => setPassword(e.currentTarget.value)}
              />
            </div>
          </div>

          <div>
            <div className="mt-2">
              <select
                id="selectOption"
                name="selectOption"
                required
                className="block w-full rounded-xl py-3 px-4"
              >
                {colleges.map((college) => (
                  <option key={college._id.$oid} value={college._id.$oid}>
                    {college.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div>
            <div className="mt-2">
              <input
                id="first-name"
                name="first-name"
                required
                placeholder="First Name"
                className="block w-full rounded-xl py-3 px-4"
                value={firstName}
                onInput={(e) => setFirstName(e.currentTarget.value)}
              />
            </div>
          </div>

          <div>
            <div className="mt-2">
              <input
                id="last-name"
                name="last-name"
                required
                placeholder="Last Name"
                className="block w-full rounded-xl py-3 px-4"
                value={lastName}
                onInput={(e) => setLastName(e.currentTarget.value)}
              />
            </div>
          </div>

          <div>
            <button type="submit" className="primary-button">
              Register
            </button>
          </div>
        </form>

        <p className="mt-10 text-center text-sm text-gray-500">
          Already have an account?
          <Link
            to="/login"
            state={{ next: state?.next }}
            className="ml-1 font-semibold leading-6 text-accent-white"
          >
            Login here.
          </Link>
        </p>
      </div>
    </div>*/
    <form onSubmit={onSubmit} action="#" method="POST" className="mt-6">
      <div className="md:flex md:space-x-16">
        <div className="w-full mb-12 md:mb-0 w-full md:w-1/3">
          <div className="mb-5">
            <label className="uppercase block  font-bold mb-1">
              {t.email}
              <input
                className="w-full py-2 rounded-lg bg-accent-primary text-accent-charcoal px-2"
                id="email"
                name="email"
                type="email"
                required
                value={email}
                onInput={(e) => setEmail(e.currentTarget.value)}
                aria-invalid={emailError ? true : false}
                aria-errormessage={emailError ? emailErrorMessageId : undefined}
              />
            </label>
            <ErrorMessage
              className="text-red-400"
              id={emailErrorMessageId}
              message={emailError}
            />
          </div>
          <div className="mb-5">
            <label className="uppercase block  font-bold mb-1">
              {t.college}
              {
                collegesStatus === "loading"
                  ? <p>{t.loading}</p>
                : collegesStatus === "failed"
                  ? <p>Error</p>
                  : <select
                    id="selectOption"
                    name="selectOption"
                    required
                    className="w-full py-2 rounded-lg bg-accent-primary text-accent-charcoal px-2"
                    onChange={e => setCollegeId(e.target.value)}
                    value={collegeId}
                  >
                    <option hidden disabled></option>
                    {colleges.map((college) => (
                      <option key={college._id.$oid} value={college._id.$oid}>
                        {college.name}
                      </option>
                    ))}
                  </select>
              }
            </label>
          </div>

          <div className="mb-5">
            <label className="uppercase block  font-bold mb-1">
              {t.username}
              <input
                className="w-full py-2 rounded-lg bg-accent-primary text-accent-charcoal px-2"
                id="username"
                name="username"
                required
                value={username}
                onInput={(e) => setUsername(e.currentTarget.value)}
                aria-invalid={usernameError ? true : false}
                aria-errormessage={usernameError ? usernameErrorMessageId : undefined}
              />
            </label>
            <ErrorMessage
              className="text-red-400"
              id={usernameErrorMessageId}
              message={usernameError}
            />
          </div>

          <div className="mb-5">
            <label className="uppercase block  font-bold mb-1">
              {t.password}
              <input
                className="w-full py-2 rounded-lg bg-accent-primary text-accent-charcoal px-2"
                id="password"
                name="password"
                type="password"
                required
                value={password}
                onInput={(e) => setPassword(e.currentTarget.value)}
                aria-invalid={passwordError ? true : false}
                aria-errormessage={passwordError ? passwordErrorMessageId : undefined}
              />
            </label>
            <ErrorMessage
              className="text-red-400"
              id={passwordErrorMessageId}
              message={passwordError}
            />
          </div>
        </div>
        <div className="w-full md:w-2/3">
          <div className="md:flex md:space-x-4">
            <div className="mb-5 md:w-1/2">
              <label className="uppercase block font-bold mb-1">
                {t.first_name}
                <input
                  className="w-full py-2 rounded-lg bg-accent-primary text-accent-charcoal px-2"
                  id="first-name"
                  name="first-name"
                  required
                  value={firstName}
                  onInput={(e) => setFirstName(e.currentTarget.value)}
                  aria-invalid={firstNameError ? true : false}
                  aria-errormessage={firstNameError ? firstNameErrorMessageId : undefined}
                />
              </label>
              <ErrorMessage
                className="text-red-400"
                id={firstNameErrorMessageId}
                message={firstNameError}
              />
            </div>

            <div className="mb-5 md:w-1/2">
              <label className="uppercase block  font-bold mb-1">
                {t.last_name}
                <input
                  className="w-full py-2 rounded-lg bg-accent-primary text-accent-charcoal px-2"
                  id="last-name"
                  name="last-name"
                  required
                  value={lastName}
                  onInput={(e) => setLastName(e.currentTarget.value)}
                  aria-invalid={lastNameError ? true : false}
                  aria-errormessage={lastNameError ? lastNameErrorMessageId : undefined}
                />
              </label>
              <ErrorMessage
                className="text-red-400"
                id={lastNameErrorMessageId}
                message={lastNameError}
              />
            </div>
          </div>
          <div className="mb-5 ">
            <Customizer avatar={avatar} setAvatar={setAvatar} />
          </div>
        </div>
      </div>

      <div className="w-full md:absolute md:top-0 md:right-0 md:w-1/4 px-6 md:h-full py-8 md:flex md:items-end">
        <div className="w-full">
          <button
            type="submit"
            // TODO Should use aria-disabled instead:
            disabled={isSubmitting}
            className="uppercase w-full py-3 px-12 rounded-lg bg-accent-persian font-bold"
          >
            Let's go!
          </button>
          <ErrorMessage
            className="text-red-400 md:text-red-900"
            id={undefined}
            message={
              !submissionError
                ? null
              : hasSubmitButtonError
                ? submissionError
                : t.generic_error
            }
          />
        </div>
      </div>
    </form>
  );
};

export default RegisterForm;
