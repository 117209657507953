import {
  useId,
  useState,
  type ReactElement,
  type ReactNode,
} from "react";
import { useAppSelector } from "../app/typedRedux";
import usePromise from "../hooks/usePromise";
import { selectTranslations } from "../redux/i18nSlice";
import ErrorMessage from "./ErrorMessage";
import SaveButton from "./SaveButton";

/**
 * Displays a value and gives users the ability to edit it.
 */
export default function Editable({
  ariaName,
  createEditComponent,
  displayComponent,
  saveCallback,
  canEdit,
}: {
  /**
   * Name presented to users through aria for this editable thing.
   */
  ariaName: string;
  /**
   * Should return a component for editing the current value.
   *
   * The component will be nested within a `<form>` element.
   *
   * @example
   * <caption>Example argument:</caption>
   * createEditComponent={({ariaErrorMessageId}) => <input
   * 	value={value}
   * 	onChange={e => setValue(e.target.value)}
   * 	aria-errormessage={ariaErrorMessageId}
   * 	aria-invalid={ariaErrorMessageId !== undefined}
   * />}
   */
  createEditComponent: (args: {
    /**
     * `id` of the element displaying an error message, or `undefined` if
     * there is no error. Should be used in an `aria-errormessage` attribute.
     *
     * If there is an error, `aria-invalid` should also be set.
     */
    ariaErrorMessageId?: string;
    save: () => void;
  }) => ReactNode;
  /** A component which displays the value when not editing it. */
  displayComponent: ReactElement;
  /** A callback which is called to save new values. */
  saveCallback: () => void | Promise<void>;
  /**
   * `true` if the value can be edited, `false` if {@link displayComponent}
   * should be drawn directly.
   */
  canEdit?: boolean;
}) {
  canEdit ??= true;

  const [isEditing, setIsEditing] = useState(false);

  const t = useAppSelector(selectTranslations);

  const {
    error: saveError,
    isPending: isSaving,
    observePromise: observeSavePromise,
    reset: resetSaveResult,
  } = usePromise();
  const errorMessage = saveError !== undefined
    ? (saveError as Error)?.message || t.generic_error
    : null;

  const ariaErrorMessageId = useId();

  function startEditing() {
    setIsEditing(true);
  }
  function stopEditing() {
    setIsEditing(false);
    resetSaveResult();
  }

  function save() {
    observeSavePromise((async () => {
      await saveCallback();
      stopEditing();
    })());
  }

  if (!canEdit) {
    // Can't edit, just use the display component directly, kinda gross since
    // we've set up all this state for nothing:
    return displayComponent;
  }

  if (isEditing) {
    return (
      <form
        aria-label={ariaName}
        className="flex flex-col"
        onSubmit={e => {
          e.preventDefault();
          save();
        }}
      >
        <div className="flex gap-4">
          {createEditComponent({
            ...(saveError !== null && { ariaErrorMessageId }),
            save,
          })}
          <SaveButton
            ariaName={ariaName}
            disabled={isSaving}
          />
          <button
            type="button"
            onClick={stopEditing}
            aria-label={t.cancel_changes_to_x.replace("{}", ariaName)}
          >
            {t.cancel}
          </button>
        </div>
        <ErrorMessage id={ariaErrorMessageId} message={errorMessage} />
      </form>
    );
  }

  return (
    <section aria-label={ariaName} className="flex gap-4">
      {displayComponent}
      <button onClick={startEditing}>{t.edit}</button>
    </section>
  );
}
