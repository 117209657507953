import { useCallback, useReducer, useRef, useState } from "react";
import isAbortError from "../util/isAbortError";

/**
 * Allows promises to be used as state.
 */
export default function usePromise<T, TError = unknown>(): {
  /**
   * The value thrown by the most recent promise, or `undefined` if the previous
   * promise hasn't thrown or a promise is pending.
   * 
   * Abort errors are ignored.
   */
  error: TError | undefined;
  /**
   * `true` while actively awaiting a promise, otherwise `false`.
   */
  isPending: boolean;
  /**
   * The result of the most recent promise. Has no value if there has been no
   * promise or an error occured.
   * 
   * If a new promise is observed, the existing result is kept until the new
   * promise resolves.
   */
  lastResult: {
    hasValue: false;
  } | {
    hasValue: true;
    value: T;
  };
  /**
   * Called with a promise whose results we want to use.
   */
  observePromise: (promise: Promise<T>) => void;
  /**
   * Resets this hook's state.
   */
  reset: () => void;
} {
  const [lastResult, setLastResult] = useState<
    ReturnType<typeof usePromise<T, TError>>["lastResult"]
  >({hasValue: false});

  const [error, setError] = useState<TError | undefined>(undefined);

  const currentPromiseRef = useRef<Promise<T> | null>(null);
  const [currentPromise, setCurrentPromise] = useReducer(
    (_oldPromise: unknown, e: Promise<T> | null) => {
      currentPromiseRef.current = e;
      return e;
    },
    null
  );

  const observePromise = useCallback((promise: Promise<T>) => {
    setCurrentPromise(promise);
    setError(undefined);

    promise
      .then(value => {
        // Ignore results if another promise has since been started:
        if (currentPromiseRef.current !== promise) return;
        setCurrentPromise(null);
        setLastResult({hasValue: true, value});
      })
      .catch(e => {
        if (currentPromiseRef.current !== promise) return;
        setCurrentPromise(null);

        if (isAbortError(e)) return;
        setLastResult({hasValue: false});
        setError(e);
      });
  }, []);

  const reset = useCallback(() => {
    setCurrentPromise(null);
    setError(undefined);
    setLastResult({hasValue: false});
  }, []);

  return {
    lastResult,
    error,
    observePromise,
    isPending: currentPromise !== null,
    reset,
  };
}
