import RegisterForm from "../components/RegisterForm";
import { Link } from "react-router-dom";
import { useAppSelector } from "../app/typedRedux";
import useRequireUnauth from "../hooks/useRequireUnauth";
import { selectTranslations } from "../redux/i18nSlice";
function Register() {
  useRequireUnauth();

  const t = useAppSelector(selectTranslations);
  return (
    <div className="min-h-screen w-full bg-accent-primary">
      <div className="min-h-screen w-full md:flex">
        <div className="w-full pr-5  min-h-screen bg-accent-charcoal pt-48 pl-5 md:px-32 md:w-3/4 text-white">
          <h2 className="uppercase text-accent-sienna text-3xl md:text-6xl">
            {t.lets_get_you_started}
          </h2>
          <p className="uppercase  mt-8">
            {t.have_account_sign_in}{" "}
            <Link className="text-accent-sandy" to="/login">
              {t.here}
            </Link>
          </p>
          <div>
            <RegisterForm />
          </div>
        </div>
        <div className="absolute">
          <img alt="" />
        </div>
      </div>
    </div>
  );
}

export default Register;
