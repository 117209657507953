import axios from "axios";
import { addAppListener } from "../app/typedRedux";
import { backendUrl } from "../config";
import { loginStateSelector } from "../redux/authSlice";

/**
 * Axios instance for access to the backend server.
 */
const backend = axios.create({
  baseURL: backendUrl
});

// HACK: Dynamically import store to avoid circular dependencies:
const storeImport = import("../redux/Store")

backend.interceptors.request.use(
  async config => {
    const store = (await storeImport).default

    if (loginStateSelector(store.getState()) === "pending") {
      // Wait until authentication is complete
      await new Promise<void>(resolve => {
        const unsubscribe = store.dispatch(addAppListener({
          predicate(_action: unknown, currentState) {
            return loginStateSelector(currentState) !== "pending"
          },
          effect() {
            resolve()
            unsubscribe()
          }
        }))
      })
    }

    config = { withCredentials: true, withXSRFToken: true, ...config }

    return config
  }
);

// Not sure if this belongs here, but WIP and can be moved
// might also want to display the pdf in the browser
export async function generateReport(userData: any) {
    try {
        const response = await axios.post('/generate-pdf', { data: userData }, { responseType: 'blob' });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'report.pdf');
        document.body.appendChild(link);
        link.click();
    } catch (error) {
        console.error('Failed to generate PDF:', error);
    }
}

export default backend
