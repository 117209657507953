interface GameData {
  title: string;
  description: string;
  subject: string;
}

function UnityGame({ iframeSrc }: { iframeSrc: string }) {
  return (
    <main className=" rounded-lg overflow-hidden">
      <iframe
        title="Game"
        src={iframeSrc}
        // TODO Style the iframe as desired; note that either width or height
        //      needs to be auto for aspect-ratio to work:
        style={{
          border: "none",
          width: "100%",
          height: "100%",
        }}
        onLoad={(e) => {
          // Size the iframe and its contents based on the game's aspect ratio
          const iframe = e.currentTarget;
          const contentDocument = iframe.contentDocument!;
          const canvas = contentDocument.getElementById(
            "unity-canvas"
          ) as HTMLCanvasElement;
          const container = contentDocument.getElementById("unity-container");

          let { width, height } = canvas;
          const aspectRatio = width + "/" + height;

          if (container) {
            // HACK Because it's easiest to size the outer iframe using
            //      aspect-ratio, we need to keep the container's aspect ratio the
            //      same as the game's; we'll do this using margins. This is only
            //      an issue for games that export using the container:
            const xMargin =
              (container.scrollHeight - height) * (width / height);
            const halfXMarginStr = xMargin * 0.5 + "px";

            const s = container.style;
            s.position = "unset";
            s.transform = "unset";
            s.width = "auto";
            s.height = "100%";
            s.marginLeft = halfXMarginStr;
            s.marginRight = halfXMarginStr;
            s.aspectRatio = aspectRatio;
          }
          iframe.style.aspectRatio = aspectRatio;
          canvas.style.width = "100%";
          canvas.style.height = "100%";
          contentDocument.body.style.overflow = "hidden";
        }}
      />
    </main>
  );
}

export { UnityGame };
export type { GameData };
