import type Avatar from "../types/Avatar";

export default function AvatarDisplay({
  avatar,
  width,
  height,
}: {
  avatar: Avatar;
  /** CSS width string to use. */
  width: string;
  /** CSS height string to use. */
  height: string;
}) {
  return (
    <div className="relative" style={{ width, height }}>
      <img
        className="relative top-0 right-0 w-full h-full object-contain z-30 "
        src={`/images/characters/accessories/${avatar.acc}`}
        alt="Avatar accessory"
      />
      <img
        className="absolute top-0 right-0 w-full h-full object-contain"
        src={`/images/characters/body/${avatar.body}`}
        alt="Avatar body Color"
      />
      <img
        className="absolute top-0 right-0 w-full h-full object-contain z-20"
        src={`/images/characters/eyes/${avatar.eyes}`}
        alt="Avatar eyes"
      />
    </div>
  );
}
