import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

import React from "react";
import type Avatar from "../types/Avatar";
import mod from "../util/mod";
import AvatarDisplay from "./AvatarDisplay";

/** Arrays of image names for each avatar image slot. */
const avatarImages: { [K in keyof Avatar]: string[] } = {
  acc: [
    "baseball-hat.png",
    "bowtie.png",
    "circle-glasses.png",
    "cowboy-hat.png",
    "square-glasses.png",
    "sunglasses.png",
    "top-hat.png",
  ],
  body: [
    "blue.png",
    "green.png",
    "lime-green.png",
    "orange.png",
    "red.png",
    "turquoise.png",
    "yellow.png",
  ],
  eyes: [
    "excited.png",
    "angry.png",
    "bored.png",
    "normal.png",
    "questioning.png",
    "sad.png",
    "wink.png",
  ],
};

/** Creates an avatar with the default image choices. */
export function createDefaultAvatar(): Avatar {
  return {
    acc: avatarImages.acc[0],
    body: avatarImages.body[0],
    eyes: avatarImages.eyes[0],
  };
}

const Customizer: React.FC<{
  avatar: Avatar;
  setAvatar: (value: Avatar) => void;
}> = ({ avatar, setAvatar }) => {
  /** Gets the index of an avatar field from the avatar. */
  function getAvatarImageIndex(field: keyof Avatar) {
    const index = avatarImages[field].indexOf(avatar[field]);
    if (index === -1) return 0;
    return index;
  }

  /** Change one of the avatar's fields by a number of indices. */
  function changeImageIndexBy(field: keyof Avatar, steps: number) {
    const images = avatarImages[field];
    const oldIndex = getAvatarImageIndex(field);
    const newIndex = mod(oldIndex + steps, images.length);

    setAvatar({
      ...avatar,
      [field]: images[newIndex],
    });
  }

  return (
    <div className="">
      <div className="relative flex space-x-1 items-center justify-between">
        <div>
          <div
            onClick={() => changeImageIndexBy("acc", -1)}
            className="cursor-ppinter rounded-full bg-[#e76f51] w-16 h-16 flex items-center justify-center mb-5"
          >
            <IoIosArrowBack className="text-2xl text-accent-charcoal" />
          </div>
          <div
            onClick={() => changeImageIndexBy("body", -1)}
            className="rounded-full bg-[#f4a261] w-16 h-16 flex items-center justify-center mb-5"
          >
            <IoIosArrowBack className="text-2xl text-accent-charcoal" />
          </div>
          <div
            onClick={() => changeImageIndexBy("eyes", -1)}
            className="rounded-full bg-[#e9c46a] w-16 h-16 flex items-center justify-center mb-5"
          >
            <IoIosArrowBack className="text-2xl text-accent-charcoal" />
          </div>
        </div>
        <div className="w-64">
          <AvatarDisplay avatar={avatar} width="100%" height="auto" />
        </div>
        <div>
          <div
            onClick={() => changeImageIndexBy("acc", 1)}
            className="relative z-50 rounded-full bg-[#e76f51] w-16 h-16 flex items-center justify-center mb-5"
          >
            <IoIosArrowForward className="text-2xl text-accent-charcoal" />
          </div>
          <div
            onClick={() => changeImageIndexBy("body", 1)}
            className="relative z-50 rounded-full bg-[#f4a261] w-16 h-16 flex items-center justify-center mb-5"
          >
            <IoIosArrowForward className="text-2xl text-accent-charcoal" />
          </div>
          <div
            onClick={() => changeImageIndexBy("eyes", 1)}
            className="relative z-50 rounded-full bg-[#e9c46a] w-16 h-16 flex items-center justify-center mb-5"
          >
            <IoIosArrowForward className="text-2xl text-accent-charcoal" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customizer;
