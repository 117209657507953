import { useEffect, useState, type DependencyList } from "react";
import isAbortError from "../util/isAbortError";

/**
 * Handles getting the result of an asynchronous, abortable function.
 * 
 * @param callback The asynchronous function to call. It is passed an
 * {@link AbortSignal} which should be given to functions like {@link fetch} or
 * the Axios methods. It should return a promise for the desired result or a
 * tuple containing the promise and a cleanup function.
 * @param dependencies Dependencies which trigger a rerun of this hook.
 * @return A tuple containing the current status and result; result is `null`
 * until the first time the promise settles, at which point it's the promise's
 * result when `"fulfilled"` or whatever was thrown when `"rejected"`.
 */
export function useAbortableWithDeps<T>(
  // For linting, keep these as the first and second args:
  callback: (signal: AbortSignal) => Promise<T> | [ result: Promise<T>, cleanup: () => void ],
  dependencies: DependencyList | undefined
)
: [ status: "pending", result: null | T ]
  | [ status: "fulfilled", result: T ]
  | [ status: "rejected", result: unknown ] {

  type Result = ReturnType<typeof useAbortableWithDeps>
  const [ status, setStatus ] = useState<Result[0]>("pending")
  const [ result, setResult ] = useState<Result[1]>(null)

  useEffect(() => {
    setStatus("pending")

    const abortController = new AbortController()

    const result = callback(abortController.signal)
    const [ promise, cleanupCallback ] = result instanceof Array
      ? result
      : [ result, () => {} ]

    promise
      .then(e => {
        setStatus("fulfilled")
        setResult(e)
      })
      .catch(e => {
        // Do nothing if aborted, since we're either unmounting or re-running
        if (isAbortError(e)) return

        setStatus("rejected")
        setResult(e)
      })

    return () => {
      abortController.abort()
      cleanupCallback()
    }
  // We need to ignore changes to the callback, or else it will be restarted
  // every re-render if the caller doesn't wrap it in useCallback:
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)

  return [ status, result ] as any
}
