// AppRouter.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Biobot from "./pages/Biobot";
import ArcanaApotheca from "./pages/ArcanaApotheca";
import UserPage from "./pages/UserPage";
import VectorShock from "./pages/VectorShock";
import Games from "./pages/Games";

const AppRouter = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/games" element={<Games />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/user/:id" element={<UserPage />} />
        <Route path="/biobot" element={<Biobot />} />
        <Route path="/arcana_apotheca" element={<ArcanaApotheca />} />
        <Route path="/vector_shock" element={<VectorShock />} />
      </Routes>
      {/*<Footer/>*/}
    </Router>
  );
};

export default AppRouter;
