import { useId, useState, type FormEvent } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../app/typedRedux";
import usePromise from "../hooks/usePromise";
import { logIn } from "../redux/authSlice";
import { useAppSelector } from "../app/typedRedux";
import { selectTranslations } from "../redux/i18nSlice";
import type { UserLoginInfo } from "../types/UserInfo";
import rethrowFormErrorContents, { type FieldErrors, type RethrownFormErrorContents } from "../util/rethrowFormErrorContents";
import ErrorMessage from "./ErrorMessage";
function LoginForm() {
  const t = useAppSelector(selectTranslations);
  const [identity, setIdentity] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useAppDispatch();

  type LoginFieldErrors = FieldErrors<UserLoginInfo>;
  type SubmissionError = RethrownFormErrorContents<UserLoginInfo>;
  const {
    error: submissionError,
    isPending: isSubmitting,
    observePromise: observeSubmissionPromise,
  } = usePromise<void, SubmissionError>();

  const {
    identity: identityError,
    password: passwordError,
  } = typeof submissionError === "object"
    ? submissionError
    // No field errors; empty object:
    : {} satisfies LoginFieldErrors;

  const identityErrorMessageId = useId();
  const passwordErrorMessageId = useId();

  const navigate = useNavigate();
  const state = useLocation().state;

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    observeSubmissionPromise((async () => {
      try {
        await dispatch(logIn({ identity, password })).unwrap();
        navigate(state?.next ?? "/games");
      } catch (er) {
        rethrowFormErrorContents(er);
      }
    })());
  }

  /**
   * `true` if there error is a specific error that should be shown by the
   * submit button, `false` if a generic error message should be shown instead.
   */
  const hasSubmitButtonError = typeof submissionError === "string";

  return (
    /*<div className="max-w-3xl m-auto px-5 py-24">
      <div className="text-accent-white">
        <img className="mx-auto h-10 w-auto" src="/images/character-white.png" />
        <h2 className="mt-5 text-center text-7xl">Login</h2>
        <p className="mt-5">We're happy to see you again!</p>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={onSubmit} action="#" method="POST">
          <div>
            <div className="mt-2">
              <input
                id="identity"
                name="identity"
                required
                placeholder="Username or Email"
                className="block w-full rounded-xl py-3 px-4"
                value={identity}
                onInput={e => setIdentity(e.currentTarget.value)}
              />
            </div>
          </div>

          <div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                required
                placeholder="Password"
                className="block w-full rounded-xl py-3 px-4"
                value={password}
                onInput={e => setPassword(e.currentTarget.value)}
              />
            </div>
          </div>

          <div>
            <button type="submit" className="primary-button">
              Sign in
            </button>
          </div>
        </form>

        <p className="mt-10 text-center text-sm text-gray-500">
          Not a member?
          <Link
            to="/register"
            state={{next: state?.next}}
            className="ml-1 font-semibold leading-6 text-accent-white"
          >
            Register now.
          </Link>
        </p>
      </div>
    </div>*/
    <form className="mt-8" onSubmit={onSubmit} action="#" method="POST">
      <div className="mb-5">
        <label className="uppercase block  font-bold mb-1">
          {t.username}
          <input
            className="w-full py-2 rounded-lg bg-accent-primary text-accent-charcoal px-2"
            id="identity"
            name="identity"
            aria-label="username"
            required
            value={identity}
            onInput={(e) => setIdentity(e.currentTarget.value)}
            aria-invalid={identityError ? true : false}
            aria-errormessage={identityError ? identityErrorMessageId : undefined}
          />
        </label>
        <ErrorMessage
          className="text-red-400"
          id={identityErrorMessageId}
          message={identityError}
        />
      </div>
      <div className="mb-5">
        <label className="uppercase block  font-bold mb-1 ">
          {t.password}
          <input
            className="w-full py-2 rounded-lg bg-accent-primary text-accent-charcoal px-2"
            id="password"
            name="password"
            type="password"
            required
            value={password}
            onInput={(e) => setPassword(e.currentTarget.value)}
            aria-invalid={passwordError ? true : false}
            aria-errormessage={passwordError ? passwordErrorMessageId : undefined}
          />
        </label>
        <ErrorMessage
          className="text-red-400"
          id={passwordErrorMessageId}
          message={passwordError}
        />
      </div>
      <div className="mt-12">
        <button
          type="submit"
          disabled={isSubmitting}
          className="uppercase w-full py-3 rounded-lg bg-accent-persian font-bold"
        >
          {t.lets_go}
        </button>
        <ErrorMessage
          className="text-red-400"
          id={undefined}
          message={
            !submissionError
              ? null
            : hasSubmitButtonError
              ? submissionError
              : t.generic_error
          }
        />
      </div>
    </form>
  );
}

export default LoginForm;
