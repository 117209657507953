import React, { useState, useEffect } from "react";
import { UnityGame, GameData } from "../components/UnityGame";
import RequireAuth from "../components/RequireAuth";

function VectorShock() {
  const [gameData, setGameData] = useState<GameData | null>(null);

  // TODO: NEED TO TWEAK URL THAT IS FETCHED
  useEffect(() => {
    const gameId = "65e8bef2556981af0ab87eab";
    fetch(`https://eduteqgamejam.info/backend/api/games/${gameId}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === "success") {
          setGameData(data.game);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  return (
    /*<RequireAuth>*/
      <div className="min-h-screen pr-5 py-32 md:py-0 md:pr-0 w-full bg-accent-primary md:flex md:space-x-7 items-center pl-5 md:px-32">
        <div className="mb-10 md:mb-0 w-full md:w-1/4 ">
          {/* Render game data */}
          {gameData && (
            <>
              <img
                className="border-4 rounded-lg border-[#2c867e]"
                src="/images/vectorshock.png"
                alt="Biobot game banner"
              />
              <div className="mt-5 bg-[#2c867e] uppercase py-3 px-4 text-center rounded-full text-white">
                {gameData.subject}
              </div>
              <h2 className="text-3xl mt-3 text-accent-charcoal">
                {gameData.title}
              </h2>
              <p className="mt-2 text-accent-charcoal">
                {gameData.description}
              </p>
            </>
          )}
        </div>
        <div className="w-full md:w-3/4">
          {/* Render Unity game */}
          <UnityGame iframeSrc="games/vector_shock/index.html" />
        </div>
      </div>
    /*</RequireAuth>*/
  );
}

export default VectorShock;
