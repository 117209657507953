import { isAxiosError } from "axios"
import { useId, useMemo, useState, type InputHTMLAttributes, type ReactElement } from "react"
import { useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../app/typedRedux"
import backend from "../axios/backend"
import AvatarDisplay from "../components/AvatarDisplay"
import AvatarCustomizer, { createDefaultAvatar } from "../components/Customizer"
import Editable from "../components/Editable"
import ErrorMessage from "../components/ErrorMessage"
import InvalidPerms from "../components/InvalidPerms"
import RequireAuth from "../components/RequireAuth"
import SaveButton from "../components/SaveButton"
import { useBackendWithDeps } from "../hooks/useAxiosWithDeps"
import { useInitColleges } from "../hooks/useEnsureCollegesAreFetched"
import usePromise from "../hooks/usePromise"
import { logOut, selectAuthInfo } from "../redux/authSlice"
import { selectAllColleges } from "../redux/collegesSlice"
import { selectTranslations } from "../redux/i18nSlice"
import type Avatar from "../types/Avatar"
import type { PickKeysByPropType } from "../types/util"
import { UserInfo, UserInfoPatch } from "../types/UserInfo"

export default function UserPage() {
  const params = useParams()

  return <main
    className="size-full flex content-center items-center justify-center"
  >
    <UserPageContent userId={params.id!} key={params.id} />
  </main>
}

function UserPageContent(
  {
    userId,
  }: {
    userId: string
  }
) {
  const authInfo = useAppSelector(selectAuthInfo)
  const t = useAppSelector(selectTranslations)
  const dispatch = useAppDispatch()

  type UserInfoResponse = {
    user: UserInfo
  }
  const [
    userInfoStatus,
    userInfoResult,
    refetchUser
  ] = useBackendWithDeps<UserInfoResponse>(() => ({
    url: `/api/users/user/${userId}`
  }), [userId])

  // Get the user's college
  useInitColleges()
  const allColleges = useAppSelector(selectAllColleges)
  /**
   * The user's {@link UserInfo.college}, or `null` if they don't have one, or
   * `undefined` if it's still being fetched.
   */
  const userCollegeId = userInfoStatus === "fulfilled"
        ? userInfoResult.user.college ?? null
        : undefined
  const college = useMemo(
    () => {
      return userCollegeId === undefined || userCollegeId === null
        ? userCollegeId
        : allColleges?.find(e => e._id.$oid === userCollegeId)
    },
    [allColleges, userCollegeId]
  )

  if (userInfoStatus === "rejected") {
    console.error(userInfoResult)
    switch (userInfoResult.response?.status) {
      case 401:
        return <RequireAuth/>
      case 403:
        return <InvalidPerms/>
      case undefined:
        return <p>Error</p>
      default:
        return <p>Error {userInfoResult.response!.status}</p>
    }
  }

  if (userInfoResult === null) {
    return <p>{t.loading}</p>
  }

  async function patchUser(patch: UserInfoPatch) {
    try {
      await backend.patch(`/api/users/user/${userId}`, patch)
    } catch (e) {
      // If we got an error for the patch data, try to throw a new error with a
      // specific message
      const patchKeys = Object.keys(patch)
      if (isAxiosError(e) && patchKeys.length === 1) {
        const errorMessage = e.response?.data[patchKeys[0]]
        if (errorMessage) throw Error(errorMessage, { cause: e })
      }
    }

    refetchUser()
  }

  const userInfo = userInfoResult.user as UserInfo
  // Avatar should always be included, but just in case:
  userInfo.avatar ??= createDefaultAvatar()

  const canEdit = authInfo.authStatus === "loggedIn"
    && authInfo.id === userInfo.id

  const collegeName = college === undefined
      ? t.loading
    // null indicates they don't have one:
    : college === null
      ? t.unknown
    : college.name

  return <div className="flex gap-4">
    <div className="flex flex-col gap-4">
      <EditableUserStringField
        canEdit={canEdit}
        createDisplayComponent={({value}) => <h1>{value}</h1>}
        displayName={t.username}
        patchUserInfo={patchUser}
        userInfo={userInfo}
        userInfoKey="username"
      />
      <EditableUserStringField
        canEdit={canEdit}
        createDisplayComponent={({name, value}) => <p>{name}{t.colon} {value}</p>}
        displayName={t.firstName}
        patchUserInfo={patchUser}
        userInfo={userInfo}
        userInfoKey="first_name"
      />
      <EditableUserStringField
        canEdit={canEdit}
        createDisplayComponent={({name, value}) => <p>{name}{t.colon} {value}</p>}
        displayName={t.lastName}
        patchUserInfo={patchUser}
        userInfo={userInfo}
        userInfoKey="last_name"
      />
      {
        // Check if the email exists, since we might not display it to some users
        // (sensitive information):
        userInfo.email
          && <EditableUserStringField
            canEdit={canEdit}
            createDisplayComponent={({name, value}) => <p>{name}{t.colon} {value}</p>}
            displayName={t.email}
            patchUserInfo={patchUser}
            userInfo={userInfo}
            userInfoKey="email"
            inputAttributes={{
              type: "email",
            }}
          />
      }
      <p>{t.college}{t.colon} {collegeName}</p>
      {
        canEdit && <PasswordEditor save={async password => {
          await patchUser({password})
          await dispatch(logOut())
        }}/>
      }
    </div>
    <EditableAvatar
      avatar={userInfo.avatar}
      canEdit={canEdit}
      save={avatar => patchUser({avatar})}
    />
  </div>
}

function EditableUserStringField(
  {
    canEdit,
    createDisplayComponent,
    displayName,
    userInfoKey,
    patchUserInfo,
    userInfo,
    inputAttributes,
  }: {
    canEdit: boolean
    createDisplayComponent: (args: {name: string, value: string}) => ReactElement
    displayName: string
    /**
     * Name of the attribute in {@link userInfo} which this field is for.
     */
    userInfoKey: PickKeysByPropType<UserInfo, string>
      & PickKeysByPropType<UserInfoPatch, string>
    patchUserInfo: (patch: UserInfoPatch) => Promise<void>
    userInfo: UserInfo
    /**
     * Attributes for the `<input>` element.
     */
    inputAttributes?: Omit<
      InputHTMLAttributes<HTMLInputElement>,
      "value" | "onChange" | "aria-errormessage" | "aria-invalid"
    >
  }
) {
  const t = useAppSelector(selectTranslations)

  const [ editValue, setEditValue ] = useState(userInfo[userInfoKey] ?? "")

  return <Editable
    ariaName={displayName}
    createEditComponent={({ariaErrorMessageId}) => (
      <label
        className="flex gap-2"
      >
        {`${displayName}${t.colon}`}
        <input
          value={editValue}
          onChange={e => setEditValue(e.target.value)}
          aria-errormessage={ariaErrorMessageId}
          aria-invalid={ariaErrorMessageId !== undefined}
          {...inputAttributes}
        />
      </label>
    )}
    displayComponent={createDisplayComponent({name: displayName, value: userInfo[userInfoKey] ?? ""})}
    saveCallback={() => patchUserInfo({ [userInfoKey]: editValue })}
    canEdit={canEdit}
  />
}

function PasswordEditor(
  {
    save
  }: {
    save: (password: string) => Promise<void>
  }
) {
  const t = useAppSelector(selectTranslations)
  const [ password, setPassword ] = useState("")

  const {
    error,
    isPending: isSaving,
    observePromise,
  } = usePromise<void, Error>()

  const descriptionId = useId()
  const errorMessageId = useId()

  return <form
    aria-label={t.password}
    className="flex flex-col"
    onSubmit={e => {
      e.preventDefault()
      observePromise(save(password))
    }}
  >
    <div className="flex gap-4">
      <label className="flex gap-2">
        New password:
        <input
          autoComplete="new-password"
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          aria-describedby={descriptionId}
          aria-errormessage={error ? errorMessageId : undefined}
          aria-invalid={error !== null}
        />
      </label>
      <SaveButton
        ariaName={t.password}
        disabled={isSaving}
      />
    </div>
    <ErrorMessage id={errorMessageId} message={error?.message}/>
    <p id={descriptionId}>
      Note: Changing your password will sign you out of all devices.
    </p>
  </form>
}

function EditableAvatar(
  {
    avatar,
    canEdit,
    save,
  }: {
    avatar: Avatar
    canEdit: boolean
    save: (avatar: Avatar) => Promise<void>
  }
) {
  const t = useAppSelector(selectTranslations)
  const [ newAvatar, setNewAvatar ] = useState(avatar)

  return <div className="flex">
    <Editable
      ariaName={t.avatar}
      canEdit={canEdit}
      createEditComponent={() => {
        return <AvatarCustomizer avatar={newAvatar} setAvatar={setNewAvatar} />
      }}
      displayComponent={<AvatarDisplay
        avatar={avatar}
        width="300px"
        height="100%"
      />}
      saveCallback={() => save(newAvatar)}
    />
  </div>
}
