import { createSlice } from "@reduxjs/toolkit";
import type { SecurityErrorType } from "../hooks/useTranslateError";
import { defaultLang, supportedLangs } from "../i18nConfig";
import { RootState } from "./Store";

type SecurityErrorMessages = Record<SecurityErrorType, string>;

const initialState = {
  lang: defaultLang, // "en" when app loads
  supportedLangs: { ...supportedLangs },
  // We'll keep our translations in Redux to start.
  // Later on we'll move them to their own files so
  // that they're easier to manage.
  translations: {
    en: {
      security_errors: {
        GENERIC_AUTHN_FAILED: "Invalid credentials.",
        EMAIL_ALREADY_ASSOCIATED: "\"{email}\" is already taken.",
        DISABLED_ACCOUNT: "Account is disabled.",
        INVALID_EMAIL_ADDRESS: "Invalid email address.",
        PASSWORD_INVALID_LENGTH: "Password must be at least {length} characters.",
        PASSWORD_TOO_SIMPLE: "Password is too simple.",
        PASSWORD_BREACHED: "Password has been involved in a data breach.",
        USER_DOES_NOT_EXIST: "User doesn't exist.",
        INVALID_PASSWORD: "Incorrect password.",
        PASSWORD_IS_THE_SAME: "Password must be different than previous one.",
        ANONYMOUS_USER_REQUIRED: "You must be logged out.",
        USERNAME_INVALID_LENGTH: "Username must be at least {min} and less than {max} characters.",
        USERNAME_ILLEGAL_CHARACTERS: "Username can only contain letters and numbers.",
        USERNAME_DISALLOWED_CHARACTERS: "Username can only contain letters and numbers.",
        USERNAME_ALREADY_ASSOCIATED: "\"{username}\" is already taken.",
      } satisfies SecurityErrorMessages,
      Welcome: "Welcome",
      About: "About",
      Games: "Games",
      FAQ: "FAQ",
      Contact: "Contact",
      avatar: "Avatar",
      cancel: "Cancel",
      cancel_changes_to_x: "Cancel changes to {}",
      colon: ":",
      edit: "Edit",
      email: "Email",
      firstName: "First name",
      generic_error: "Something went wrong!",
      lastName: "Last name",
      loading: "Loading...",
      password: "Password",
      ratings: "Ratings",
      save: "Save",
      save_x: "Save {}",
      tagline: "Continuous improvement",
      unknown: "Unknown",
      username: "Username",
      welcome_back: "Welcome back!",
      no_account_create_one: "Dont' have an account? Create one",
      here: "Here",
      lets_go: "Let's go!",
      lets_get_you_started: "Let's get you started!",
      have_account_sign_in: "Already have an account? sign in",
      college: "College",
      first_name: "First Name",
      last_name: "Last Name",
      embark_on_a_learning_journey: "embark on a learning journey",
      eduteq_games: "EduteQ EduGames",
      bilogy: "Biology",
      physics: "Physics",
      chemistry: "Chemistry",
    },
    fr: {
      security_errors: {
        GENERIC_AUTHN_FAILED: "Identifiants invalides.",
        EMAIL_ALREADY_ASSOCIATED: "« {email} » est déjà pris.",
        DISABLED_ACCOUNT: "Le compte est désactivé.",
        INVALID_EMAIL_ADDRESS: "Email invalide.",
        PASSWORD_INVALID_LENGTH: "Le mot de passe doit comporter au moins {length} caractères.",
        PASSWORD_TOO_SIMPLE: "Le mot de passe est trop simple.",
        PASSWORD_BREACHED: "Le mot de passe a fait l'objet d'une violation de données.",
        USER_DOES_NOT_EXIST: "L'utilisateur n'existe pas.",
        INVALID_PASSWORD: "Mot de passe incorrect.",
        PASSWORD_IS_THE_SAME: "Le mot de passe doit être différent du précédent.",
        ANONYMOUS_USER_REQUIRED: "Vous devez être déconnecté.",
        USERNAME_INVALID_LENGTH: "Le nom d'utilisateur doit comporter au moins {min} et moins de {max} caractères.",
        USERNAME_ILLEGAL_CHARACTERS: "Le nom d'utilisateur ne peut contenir que des lettres et des chiffres.",
        USERNAME_DISALLOWED_CHARACTERS: "Le nom d'utilisateur ne peut contenir que des lettres et des chiffres.",
        USERNAME_ALREADY_ASSOCIATED: "« {username} » est déjà pris.",
      } satisfies SecurityErrorMessages,
      Welcome: "Bienvenue",
      About: "A propos",
      Games: "Jeux",
      FAQ: "FAQ",
      Contact: "Contact",
      avatar: "Avatar",
      cancel: "Annuler",
      // TODO We need an i18n library, this is grammatically wrong:
      cancel_changes_to_x: "Annuler les modifications de {}",
      colon: " :",
      edit: "Modifier",
      email: "Email",
      firstName: "Prénom",
      generic_error: "Quelque chose s'est mal passé !",
      lastName: "Nom de famille",
      loading: "Chargement...",
      password: "Mot de passe",
      ratings: "Évaluations",
      save: "Enregistrer",
      save_x: "Enregistrer {}",
      tagline: "Amélioration continue",
      unknown: "Inconnu(e)",
      username: "Nom d'utilisateur",
      welcome_back: "Bienvenue !",
      no_account_create_one: "Vous n'avez pas de compte ? Créez-en un",
      here: "Ici",
      lets_go: "Allons-y !",
      lets_get_you_started: "Commençons !",
      have_account_sign_in: "Vous avez déjà un compte ? Connectez-vous",
      college: "Université",
      first_name: "Prénom",
      last_name: "Nom",
      embark_on_a_learning_journey: "Embarquez pour un voyage d'apprentissage",
      eduteq_games: "EduteQ EduJeux",
      Biology: "Biologie",
      physics: "Physique",
      chemistry: "Chimie",
    },
  },
};
export const i18nSlice = createSlice({
  name: "i18n",
  initialState,
  reducers: {
    setLang: (state, action) => {
      state.lang = action.payload;
    },
  },
});

export const { setLang } = i18nSlice.actions;
export const selectTranslations = (state: RootState) =>
  state.i18n.translations[
    state.i18n.lang as keyof typeof initialState.translations
  ];

export const selectLang = (state: RootState) => state.i18n.lang;
export const selectSupportedLangs = (state: RootState) =>
  state.i18n.supportedLangs;

/**
 * Global regex which finds named parameters in a template string (ex: `"Hello
 * {name}"`) and captures the parameters' names (ex: `"name"`).
 */
export const paramRegex = /\{(\w+?)\}/g;

export default i18nSlice.reducer;
