import type { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import axios from "axios";
import { useReducer, type DependencyList } from "react";
import backend from "../axios/backend";
import { useAbortableWithDeps } from "./useAbortableWithDeps";

type AxiosWithDepsReturnType<T> = [
  ... _: [status: "pending", result: null | T]
    | [status: "fulfilled", result: T]
    | [status: "rejected", result: AxiosError],
  refetch: () => void
]

/**
 * Gets the asynchronous results of an axios request.
 * 
 * @param createAxiosConfig Function which returns the axios request config to
 * use.
 * 
 * Note that, unless configured otherwise, the default HTTP method is GET.
 * @param dependencies Dependencies which trigger a refetch.
 * @param axiosInstance Axios instance to use. Defaults to the static
 * {@link axios} instance.
 * @return A tuple containing the status of the asynchronous request, its result
 * according to {@link useAbortableWithDeps}, and a refetch function which
 * repeats the request when called.
 */
export default function useAxiosWithDeps<T = unknown>(
  // For linting, keep these as the first and second args:
  createAxiosConfig: () => Omit<AxiosRequestConfig, "signal">,
  dependencies: DependencyList | undefined,
  axiosInstance: AxiosInstance = axios
): AxiosWithDepsReturnType<T> {
  // Use a number that counts up as a way of updating dependencies:
  const [ refetchCount, refetch ] = useReducer(e => e + 1, 0)

  const [ status, result ] = useAbortableWithDeps(async signal => {
    return (await axiosInstance({ ...createAxiosConfig(), signal })).data
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axiosInstance, refetchCount, ...dependencies ?? []])

  return [ status, result, refetch ]
}

/** Version of {@link useAxiosWithDeps} which uses {@link backend}. */
export function useBackendWithDeps<T = unknown>(
  // For linting, keep these as the first and second args:
  createAxiosConfig: () => Omit<AxiosRequestConfig, "signal">,
  dependencies: DependencyList | undefined
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useAxiosWithDeps<T>(createAxiosConfig, dependencies, backend)
}
