import LoginForm from "../components/LoginForm";
import { Link } from "react-router-dom";
import { useAppSelector } from "../app/typedRedux";
import useRequireUnauth from "../hooks/useRequireUnauth";
import { selectTranslations } from "../redux/i18nSlice";
function Login() {
  useRequireUnauth();

  const t = useAppSelector(selectTranslations);
  return (
    <div className="min-h-screen w-full bg-accent-primary ">
      <div className=" px-5 md:px-32 md:flex md:space-x-16 h-full items-center">
        <div className="w-full md:w-1/2 py-24">
          <div className="bg-accent-charcoal rounded-lg py-16 px-8 md:px-8 text-white ">
            <div className="w-full">
              <h2 className="uppercase text-accent-sienna text-3xl md:text-6xl">
                {t.welcome_back}
              </h2>
              <p className="uppercase  mt-8">
                {t.no_account_create_one}{" "}
                <Link className="text-accent-sandy" to="/register">
                  {t.here}
                </Link>
              </p>
              <LoginForm />
            </div>
          </div>
        </div>
        <div className="hidden md:block md:w-1/2">
          <img
            className="scale-x-[-1] animate-jump"
            src="/images/mascot.svg"
            alt="Eduteq Mascot"
          />
        </div>
      </div>
    </div>
  );
}

export default Login;
