import { useCallback } from "react";
import { useAppSelector } from "../app/typedRedux";
import { paramRegex, selectTranslations } from "../redux/i18nSlice";

// See flask_security/core.py; more messages might need to be added as features
// are enabled:
export type SecurityErrorType =
  "GENERIC_AUTHN_FAILED"
  | "EMAIL_ALREADY_ASSOCIATED"
  | "DISABLED_ACCOUNT"
  | "INVALID_EMAIL_ADDRESS"
  | "PASSWORD_INVALID_LENGTH"
  | "PASSWORD_TOO_SIMPLE"
  | "PASSWORD_BREACHED"
  | "USER_DOES_NOT_EXIST"
  | "INVALID_PASSWORD"
  | "PASSWORD_IS_THE_SAME"
  | "ANONYMOUS_USER_REQUIRED"
  | "USERNAME_INVALID_LENGTH"
  // When the string contains characters that *might* cause XSS:
  | "USERNAME_ILLEGAL_CHARACTERS"
  | "USERNAME_DISALLOWED_CHARACTERS"
  | "USERNAME_ALREADY_ASSOCIATED";

const securityTypePrefix = "SECURITY_MSG_";

type SecurityError = {
  type: `${typeof securityTypePrefix}${SecurityErrorType}`;
  args?: Record<string, string>;
};

function IsSecurityError(obj: any): obj is SecurityError {
  return typeof obj?.type === "string"
    && obj.type.startsWith(securityTypePrefix);
}

/**
 * Returns a function that attempts to localize error messages.
 */
export default function useTranslateError() {
  const t = useAppSelector(selectTranslations);

  const translate = useCallback((errorMessage: string) => {
    try {
      const data = JSON.parse(errorMessage);

      try {
        // Handle security errors
        if (IsSecurityError(data)) {
          // Remove prefix:
          const type = data.type.slice(
            securityTypePrefix.length
          ) as SecurityErrorType;

          const msgTemplate = t.security_errors[type];

          // Fill in the message template with the error's args:
          if (msgTemplate) {
            return msgTemplate.replaceAll(
              paramRegex,
              // args should be present if we have parameters:
              (_, paramName) => data.args![paramName]
            );
          }
        }
      } catch {
        console.error(errorMessage);
      }
    } catch {
      // String was not JSON data; ignore
    }

    console.debug("Didn't translate:", errorMessage);
    return errorMessage;
  }, [t]);

  return translate;
}
