import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/typedRedux";
import { fetchColleges, selectCollegesStatus } from "../redux/collegesSlice";

// TODO Replace this before something breaks.
/**
 * Ensures that the colleges slice is fetched if it hasn't been already.
 * 
 * @deprecated This is a really gross temporary solution. We shouldn't have to
 * remember to start fetching every time we want to use the colleges slice,
 * something is going to break, and it might be hard to spot since something
 * else might have coincidentally fetched them.
 * 
 * Maybe the best solution is to just call this in the root component, but that
 * could damage loading times across the app. I'm too tired to think about
 * whether that matters.
 */
export function useInitColleges() {
  const dispatch = useAppDispatch();
  const status = useAppSelector(selectCollegesStatus);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchColleges());
    }
  }, [dispatch, status]);
}
