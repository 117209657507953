import { type AxiosError } from "axios";
import store from "../redux/Store";
import { selectTranslations } from "../redux/i18nSlice";

/**
 * A {@link Record} which maps fields in {@link T} to error messages for each
 * field.
 */
export type FieldErrors<T> = Partial<Record<keyof T, string>>;

/**
 * Either a {@link FieldErrors} of {@link T} or a descriptive string if an error
 * occured unrelated to form fields.
 */
export type RethrownFormErrorContents<T> = Partial<Record<keyof T, string>> | string;

/**
 * Takes an error that is thrown by a form endpoint and throws a
 * {@link RethrownFormContents}.
 * 
 * Assumes that the error is thrown by an Axios method.
 */
export default function rethrowFormErrorContents(error: unknown) {
  if (
    // Doesn't work, no idea why:
    // isAxiosError(error)
    Math.floor(((error as AxiosError)?.response?.status ?? 0) / 100) === 4
    && (error as AxiosError).response?.data
  ) {
    const DELIMITER = " / ";

    let data = (error as AxiosError<any>).response!.data;

    if (
      typeof data !== "string"
      && !((error as AxiosError)
        .response!
        .headers["content-type"] as string)
        .startsWith("application/json")
    ) {
      // The error message isn't JSON, but Axios assumes it is if it can be
      // parsed; this is a problem since we're intentionally using JSON strings
      // for localization:
      data = JSON.stringify(data);
    }

    if (typeof data === "string") throw data;
    if (data instanceof Array) throw data.join(DELIMITER);

    const fieldErrors = {...data};
    for (const key in fieldErrors) {
      const val = fieldErrors[key];
      if (val instanceof Array) {
        fieldErrors[key] = val.join(DELIMITER);
      }
    }

    // eslint-disable-next-line no-throw-literal
    throw fieldErrors;
  }
  // eslint-disable-next-line no-throw-literal
  throw (error as Error)?.message
    || selectTranslations(store.getState()).generic_error;
}
