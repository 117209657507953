import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/typedRedux";
import { logOut, selectAuthInfo } from "../redux/authSlice";
import { selectTranslations } from "../redux/i18nSlice";
import { LanguageSwitcher } from "./LanguageSwitcher";
import React, { useState } from "react";
import "./Header.css";
import { FaSignOutAlt, FaUser, FaGamepad, FaCog } from "react-icons/fa";

function Header() {
  const dispatch = useAppDispatch();
  const authInfo = useAppSelector(selectAuthInfo);
  const t = useAppSelector(selectTranslations);

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogoutClick = () => {
    setIsOpen(false);
    dispatch(logOut());
  };

  const profileUrl = authInfo.authStatus === "loggedIn"
    ? `/user/${authInfo.id}`
    : "/register"

  return (
    <header className="py-10 text-white absolute">
      <div className="gear-wrapper">
        <div
          className="gear"
          style={{
            background:
              "url('/images/blue-wide-gear.png') no-repeat center center",
            backgroundSize: "contain",
            animation: "rotateGear 10s linear infinite",
          }}
        ></div>
        <div className={`base ${isOpen ? "close" : ""}`}>
          <div className="menu" onClick={toggleMenu}>
            <div className="icon">
              {isOpen ? (
                <>
                  <div className="bar rotate-bar1"></div>
                  <div className="bar rotate-bar2"></div>
                </>
              ) : (
                <>
                  <div className="bar bar1"></div>
                  <div className="bar bar2"></div>
                  <div className="bar bar3"></div>
                </>
              )}
            </div>
          </div>
          <div className="icons">
            {(authInfo.authStatus === "loggedOut" || authInfo.authStatus === "pending" || authInfo.authStatus === "unknown") ? (
              <Link to="/login" onClick={toggleMenu} className="menu-icon-1" aria-label="Login">
                <FaUser />
              </Link>
            ) : authInfo.authStatus === "loggedIn" ? (
              <Link to="/" onClick={handleLogoutClick} className="menu-icon-1" aria-label="Home">
                <FaSignOutAlt />
              </Link>
            ) : null}
            <Link to="/games" onClick={toggleMenu} className="menu-icon-2" aria-label="Games">
              <FaGamepad />
            </Link>
            <Link to={profileUrl} onClick={toggleMenu} className="menu-icon-3" aria-label="Profile">
              <FaCog />
            </Link>
          </div>
          <div className="section">
            <div className="cover1">
              <div className="cover2">
                <div className="content"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );

  /*{
    
    {signedIn === "loggedOut" ? (
            <div>
              <Link to="/login">Login</Link>
              <Link to="/register">Signup</Link>
            </div>
          ) : signedIn === "loggedIn" ? (
            <div>
              <Link to="/" onClick={(e) => dispatch(logOut())}>
                Logout
              </Link>
            </div>
          ) : (
            <div>
              <p>Loading...</p>
            </div>
          )}

          <LanguageSwitcher />
            
  }*/
}

export default Header;
